import { useState, useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import Navigations from './navigations';
import Navbar from './components/navbar';
import Footer from './components/footer';
import ContactForm from './components/form/contact';
import { useNavigate } from 'react-router-dom';

function App() {
  const [isContactPage, setIsContactPage] = useState(false);
  const navigation = useNavigate();

  useEffect(() => {
    setIsContactPage(navigation.pathname === '/contact');
  }, [navigation.pathname]);

  return (
    <div className="bg-orange-1 text-orange-9 font-poppins">
      <Navbar />
      <div className="py-15 px-2 md:px-4 mx-auto overflow-hidden">
        <Navigations />

        {isContactPage ? (
          <section className="bg-gradient-to-b from-orange-1 via-orange-2 to-orange-3 px-4 py-8 md:px-16 md:py-12 shadow-2xl rounded-sm">
            <ContactForm />
          </section>
        ) : null}

      </div>
      <Footer />
      <Toaster />
    </div>
  );
}

export default App;

import Slider from "react-slick";

const VerticalMode = ({ children }) => {
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
        autoplay: true,
        speed: 3000,
        autoplaySpeed: 3000,
        pauseOnHover: true,
        arrows: false,
    };
    return (
        <Slider {...settings}>
            {children}
        </Slider>
    );
}

export default VerticalMode;

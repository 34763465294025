import { useState, useEffect, useRef } from 'react'
import { motion } from "framer-motion";
import debounce from 'debounce';

import { useFirestore } from '../../context/FirestoreContext';
import useBounceAnimation from '../../hooks/animations/useBounceAnimation';
import useSmoothScroll from '../../hooks/general/useSmoothScroll'

import BlogImg1 from '../../assests/images/temprary/blog1.jpg'
import BlogImg2 from '../../assests/images/temprary/blog2.jpg'

import ImageCard from '../../components/cards/imageoverlap';
import BlogCard from '../../components/cards/blog';
import HorizontalBlogCard from '../../components/cards/blog/HorizontalBlogCard';

import VerticalMode from '../../components/carousel/VerticleCarousel';
import MultipleRowsCarousel from '../../components/carousel/MultipleRowsCarousel';

const InsightContent = () => {
    useSmoothScroll();

    const bounceAnimationProps = useBounceAnimation();

    return (
        <motion.section {...bounceAnimationProps} className='bg-gradient-to-br from-orange-2 from-15% via-orange-1 to-white to-75% py-10'>
            <Blogs />
        </motion.section>
    )
}

const Blogs = () => {

    const [blogData, setBlogData] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    const { getTeamData: getBlogData } = useFirestore()

    const handleFetchAllBlogsData = useRef(debounce(async () => {
        setIsLoading(true)
        try {
            const res = await getBlogData('blogs');
            let data = [];
            for (let key in res) {
                if (!res[key].archive) {
                    data.push(res[key])
                }
            }
            setBlogData(data)
        } catch (error) {
            console.error('error while fetching blog data: ', error);
        } finally {
            setIsLoading(false)
        }
    }, 700));

    useEffect(() => {
        handleFetchAllBlogsData.current();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <section className='bg-inherit p-4'>

            <section className="grid grid-cols-1 md:grid-cols-5 gap-1 md:h-min w-full">
                <aside className='col-span-1 md:col-span-3'>
                    {!isLoading && blogData.length !== 0 ? (
                        <ImageCard
                            imageSrc={BlogImg1}
                            date={blogData[0].date}
                            domain={blogData[0].domain}
                            title={blogData[0].blogTitle}
                            largecard={true}
                            link={`/insight/${blogData[0].id}`}
                        />
                    ) : (
                        <div className="skeleton-pulse h-80 rounded-xl"></div>
                    )}
                </aside>

                <aside className='col-span-1 md:col-span-2 my-auto hidden md:block'>
                    <VerticalMode>
                        {
                            blogData.length !== 0 && !isLoading ?
                                (
                                    blogData
                                        .reverse()
                                        .slice(0, 10)
                                        .map((data) => (
                                            <HorizontalBlogCard
                                                key={data.id}
                                                image={BlogImg2}
                                                title={data.blogTitle}
                                                desc={data.blogDesc}
                                                link={`/insight/${data.id}`}
                                            />
                                        ))
                                ) : (
                                    <div className="skeleton-pulse h-[6.2rem] rounded-xl" />
                                )
                        }
                    </VerticalMode>
                </aside>
            </section>

            <section className="my-2">
                <MultipleRowsCarousel>
                    {
                        blogData.length !== 0 && !isLoading &&
                        blogData
                            // .slice(7, 13)
                            .map((data) => (
                                <BlogCard
                                    key={data.id}
                                    image={BlogImg2}
                                    domain={data.domain}
                                    topic={data.blogTitle}
                                    desc={data.blogDesc}
                                    link={`/insight/${data.id}`}
                                />
                            ))
                    }
                </MultipleRowsCarousel>
            </section>

            <section className='my-8'>
                <h2 className='text-black text-xl font-normal my-4'>More Blogs ... </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-2">

                    {
                        blogData.length !== 0 && !isLoading &&
                        blogData
                            .slice(1, 7)
                            .map((data) => (
                                <BlogCard
                                    key={data.id}
                                    image={BlogImg2}
                                    domain={data.domain}
                                    topic={data.blogTitle}
                                    desc={data.blogDesc}
                                    link={`/insight/${data.id}`}
                                />
                            ))
                    }

                </div>
            </section>
        </section>
    )
}

export default InsightContent;
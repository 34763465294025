import { useState, useEffect, useRef } from 'react'
import { useParams, Link } from 'react-router-dom';

import useSmoothScroll from '../../../hooks/general/useSmoothScroll';
import { useFirestore } from '../../../context/FirestoreContext';
import LazyLoadImg from '../../../components/lazy-loading/img/LazyLoadImage';

import HelmetComponent from '../../../helmet';

import Img from '../../../assests/images/logo1.png'
import BlogCardImg from '../../../assests/images/temprary/blog2.jpg'
import VerticalMode from '../../../components/carousel/VerticleCarousel';
import HorizontalBlogCard from '../../../components/cards/blog/HorizontalBlogCard';
import debounce from 'debounce';

const Blog = () => {
    useSmoothScroll();

    const [blogData, setBlogData] = useState()
    const [loading, setLoading] = useState(false);
    // const [readingTime, setReadingTime] = useState(0);
    const [allBlogs, setAllBlogs] = useState()

    const { blog: blogId } = useParams();
    const { getTeamData: getAllBlogs } = useFirestore();

    // const formatDate = (dateString) => {
    //     const options = { day: 'numeric', month: 'short', year: 'numeric' };
    //     return new Date(dateString).toLocaleDateString('en-US', options);
    // };

    // const calculateReadingTime = (htmlContent) => {
    //     const plainText = htmlContent.replace(/<[^>]+>/g, '');

    //     const wordsPerMinute = 200;

    //     const words = plainText.trim().split(/\s+/);

    //     const wordCount = words.length;

    //     const timeInMinutes = wordCount / wordsPerMinute;

    //     const roundedTime = Math.ceil(timeInMinutes);

    //     setReadingTime(roundedTime);
    // };

    const fetchAllBlogsData = useRef(
        debounce(async () => {
            setLoading('true');
            try {
                const res = await getAllBlogs('blogs');
                for (const blog in res) {
                    if (res[blog].id === blogId) {
                        setBlogData(res[blog])
                    }
                }
                setAllBlogs(res.reverse())
            } catch (error) {
                console.error('Error while fetching all blogs data: ', error);
            } finally {
                setLoading(false);
            }
        }, 700)
    )

    useEffect(() => {

        fetchAllBlogsData.current();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // const writersBlog = allBlogs.filter(data => data.writerName === blogData.writerName)
    return (
        <section className='py-6 space-y-10 bg-gradient-to-br from-orange-1 to-white rounded-xl p-2'>

            <HelmetComponent
                title={blogId}
                desc={blogData?.desc}
                author={blogData?.writerName}
                page={blogId}
                blogKeywords={blogData?.metaTags}
                focusKeywords={blogData?.focusKeywords && blogData.focusKeywords}
            />

            {
                blogData && (
                    <>
                        <div className="breadcrumbs hidden md:block text-sm">
                            <ul>
                                <li>
                                    <Link className='text-orange-6' to='/'>
                                        BrandLadder
                                    </Link>
                                </li>
                                <li>
                                    <Link className='text-orange-6' to='/insight'>
                                        Articles
                                    </Link>
                                </li>
                                <li className='font-medium'>{blogData.blogTitle}</li>
                            </ul>
                        </div>
                    </>
                )
            }

            {/* <section className="flex"> */}
            <section className="grid grid-cols-1 md:grid-cols-3 gap-8">

                {
                    loading ? (
                        <div className="col-span-2 mx-auto">
                            {/* <loader /> */}
                            <div className="skeleton-pulse h-full w-full"></div>
                        </div>
                    ) : (
                        blogData && (
                            <aside className="space-y-6 col-span-2">
                                <h1 className='text-black font-bold text-xl md:text-3xl text-center md:text-left'>{blogData.blogTitle}</h1>

                                {/* <div className="grid grid-cols-5 gap-2">
                                    <div className='rounded-md col-span-2 border-2 border-black'>
                                        <LazyLoadImg src={Img} alt="" className='h-full w-full rounded-full mx-auto my-auto' />
                                        <LazyLoadImg src={blogData.titleImage} alt="" className='rounded-full mx-auto my-auto' />
                                    </div>
                                    <div className='col-span-3'>
                                        <p className='text-xs'>
                                            {formatDate(blogData.date)} <br />
                                            {readingTime} min{readingTime !== 1 ? 's' : ''} read
                                        </p>
                                    </div>
                                </div> */}

                                <div className="grid grid-cols-1 md:grid-cols-3 gap-2 h-fit">
                                    <div className='col-span-1 mx-auto max-w-full'>
                                        <LazyLoadImg src={Img} alt={blogData.blogTitle} className='h-full w-full' />
                                    </div>
                                    <div className="col-span-2 my-auto text-black">
                                        {blogData.blogDesc}
                                    </div>
                                </div>

                                <p dangerouslySetInnerHTML={{ __html: blogData.blogContent }} />

                                {/* {blogData.domain}
                                {blogData.metaTags} */}
                            </aside>
                        )
                    )
                }

                <aside className="col-span-1 mx-auto hidden md:block ">

                    <p className="text-orange-6">Latest Blogs ... </p>

                    {/* <div className="">
                        <MultipleRowsCarousel>
                            {
                                allBlogs ?
                                    (
                                        allBlogs.map((data) => (
                                            <BlogCard key={data.id}
                                                image={BlogCardImg} topic={data.blogTitle} desc={data.blogDesc} link={`/insight/${data.id}`} domain={data.domain} />
                                        ))
                                    ) : (
                                        <div className="skeleton-pulse h-[6.2rem] rounded-xl" />
                                    )
                            }
                        </MultipleRowsCarousel>
                    </div> */}
                    <VerticalMode>
                        {
                            allBlogs ?
                                (
                                    allBlogs
                                        .reverse()
                                        .map((data) => (
                                            <HorizontalBlogCard key={data.id} image={BlogCardImg} title={data.blogTitle} desc={data.blogDesc} />
                                        ))
                                ) : (
                                    <div className="skeleton-pulse h-[6.2rem] rounded-xl" />
                                )
                        }
                    </VerticalMode>
                </aside>
            </section>

            {/* <section className='md:px-8'>
                {
                    loading ? (
                        <Loader />
                    ) : (
                        <>
                            <p className="text-orange-6">
                                More From {blogData?.writerName} ...
                            </p>
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                                {
                                    allBlogs && blogData &&
                                    allBlogs
                                        .filter(data => data?.writerName === blogData?.writerName)
                                        .filter(data => data?.archive === false)
                                        .map(data => (
                                            <BlogCard
                                                key={data.id}
                                                image={data.titleImage}
                                                topic={data.blogTitle}
                                                desc={data.blogDesc}
                                                link={`/insight/${data.id}`}
                                                domain={data.domain}
                                            />
                                        ))
                                }
                            </div>
                        </>
                    )
                }

            </section> */}
        </section>
    )
}

export default Blog

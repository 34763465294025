import React from 'react'

const Career = () => {
    return (
        <div>
            Career
        </div>
    )
}

export default Career

export const clientsLogo = [
    'https://firebasestorage.googleapis.com/v0/b/brandladder-webapp.appspot.com/o/clients%2F1.png?alt=media&token=889c05ca-3bab-40c6-a4c8-89344b96cc7ede%201',
    'https://firebasestorage.googleapis.com/v0/b/brandladder-webapp.appspot.com/o/clients%2F0.png?alt=media&token=0e5ad6f2-072c-45c8-8b3f-8428f74f4282',
    'https://firebasestorage.googleapis.com/v0/b/brandladder-webapp.appspot.com/o/clients%2F10.png?alt=media&token=64316c7d-d5ea-468e-9d2e-4a61d8a23e07',
    'https://firebasestorage.googleapis.com/v0/b/brandladder-webapp.appspot.com/o/clients%2F11.png?alt=media&token=b17eb99e-0bc6-4bc2-8f70-b24bef341357',
    'https://firebasestorage.googleapis.com/v0/b/brandladder-webapp.appspot.com/o/clients%2F3.png?alt=media&token=929279f8-77f8-457e-8bf5-808dfc7696c8',
    'https://firebasestorage.googleapis.com/v0/b/brandladder-webapp.appspot.com/o/clients%2F4.png?alt=media&token=58aee82d-3777-433e-b129-a716975bf9aa',
    'https://firebasestorage.googleapis.com/v0/b/brandladder-webapp.appspot.com/o/clients%2F5.png?alt=media&token=269302eb-c95d-482f-90ec-c76d0d18a406',
    'https://firebasestorage.googleapis.com/v0/b/brandladder-webapp.appspot.com/o/clients%2F6.jpg?alt=media&token=28e44834-414b-4898-9ae2-f04d336370bd',
    'https://firebasestorage.googleapis.com/v0/b/brandladder-webapp.appspot.com/o/clients%2F7.png?alt=media&token=2b967ea5-3df4-4909-b83b-fe14ca5d1af3',
    'https://firebasestorage.googleapis.com/v0/b/brandladder-webapp.appspot.com/o/clients%2F8.jpg?alt=media&token=1b4f7d60-4209-4cbe-909f-7b8292977684',
    'https://firebasestorage.googleapis.com/v0/b/brandladder-webapp.appspot.com/o/clients%2F9.png?alt=media&token=7d1ff63c-07c6-4eed-b2d8-d1e5bf4d1855',
];
import React from 'react';

const Test = () => {

    return (
        <h1>
            testing screen
        </h1>
    );
};

export default Test;

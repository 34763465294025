import React from 'react'
import { Link } from 'react-router-dom';

const HorizontalBlogCard = ({ image, title, desc, link }) => {

    const topic = title.length > 50 ? title.substring(0, 30) + ' ...' : title;
    const description = desc.length > 100 ? desc.substring(0, 70) + ' ...' : desc;

    return (
        <Link className='grid grid-cols-3 gap-1 shadow-lg h-1/5 rounded-lg z-20 my-2' to={link}>
            <aside className='col-span-1 overflow-hidden'>
                <img src={image} alt="imadsage" className='size-full' />
            </aside>
            <aside className='col-span-2'>
                <h2 className='text-lg text-black font-medium'>{topic}</h2>
                <small className='text-gray-500 text-xs font-medium'>{description}</small>
            </aside>
        </Link>
    )
}

export default HorizontalBlogCard

import { Link } from "react-router-dom"

const Error = () => {
    return (
        <div className="flex flex-col items-center justify-center py-20 bg-gray-100">
            <h1 className="text-6xl font-bold text-red-500   
 mb-4">404</h1>
            <p className="text-2xl text-gray-500 mb-8">Page not found.</p>
            <Link to='/'>
                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    Go Back
                </button>
            </Link>
        </div>
    )
}

export default Error
